<template>
    <div class="page-recommend">
        <router-view />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
export default class PageRecommend extends Vue{}
</script>

<style lang="scss" scoped>
.page-recommend{
    @extend .flex-column;
}
</style>